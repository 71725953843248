<template>
    <div class="__notification">
        <div id="profile" class="mnotify-card">
            <div  id="top__header" class="mnotify-card-header">
                <div class="__header__text">
                    <h3 class="mnotify-card-header-text pt-1">Notifications</h3>
                    <div class="__badge">10</div>
                </div>
                <button class="clear__all">Clear All</button>
            </div>
            <div class="mnotify-card-body">
                <div class="__list__container">
                    <ul>
                        <li v-for="(notification, key) in notifications" :key="key">
                            <div class="__icon">
                                <img src="../../../assets/Setting.svg" alt="gear icon for settings">
                            </div>
                            <div class="__title">
                                <h3>{{notification.title}}</h3>
                                <p>{{notification.date}}</p>
                            </div>
                            <div class="__body">
                                <p>{{notification.body}}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'notifications',
    data() {
        return {
            notifications: [
                {
                    title:'System Notification',
                    date:'Wed 24 Mar, 2021 13:24:28',
                    body:'Amet, ultricies suspendisse ante amet sit pulvinar rhoncus quisque. Orci nulla bibendum risus, egestas nisl turpis. Enim proin aliquet massa dictum ut est. Diam vel id nulla proin. Convallis habitant nibh eu arcu nulla sapien quam risus, '
                },
                {
                    title:'System Notification',
                    date:'Wed 24 Mar, 2021 13:24:28',
                    body:'Amet, ultricies suspendisse ante amet sit pulvinar rhoncus quisque. Orci nulla bibendum risus, egestas nisl turpis. Enim proin aliquet massa dictum ut est. Diam vel id nulla proin. Convallis habitant nibh eu arcu nulla sapien quam risus, '
                },
                {
                    title:'System Notification',
                    date:'Wed 24 Mar, 2021 13:24:28',
                    body:'Amet, ultricies suspendisse ante amet sit pulvinar rhoncus quisque. Orci nulla bibendum risus, egestas nisl turpis. Enim proin aliquet massa dictum ut est. Diam vel id nulla proin. Convallis habitant nibh eu arcu nulla sapien quam risus, '
                },
                {
                    title:'System Notification',
                    date:'Wed 24 Mar, 2021 13:24:28',
                    body:'Amet, ultricies suspendisse ante amet sit pulvinar rhoncus quisque. Orci nulla bibendum risus, egestas nisl turpis. Enim proin aliquet massa dictum ut est. Diam vel id nulla proin. Convallis habitant nibh eu arcu nulla sapien quam risus, '
                },
                {
                    title:'System Notification',
                    date:'Wed 24 Mar, 2021 13:24:28',
                    body:'Amet, ultricies suspendisse ante amet sit pulvinar rhoncus quisque. Orci nulla bibendum risus, egestas nisl turpis. Enim proin aliquet massa dictum ut est. Diam vel id nulla proin. Convallis habitant nibh eu arcu nulla sapien quam risus, '
                },
                {
                    title:'System Notification',
                    date:'Wed 24 Mar, 2021 13:24:28',
                    body:'Amet, ultricies suspendisse ante amet sit pulvinar rhoncus quisque. Orci nulla bibendum risus, egestas nisl turpis. Enim proin aliquet massa dictum ut est. Diam vel id nulla proin. Convallis habitant nibh eu arcu nulla sapien quam risus, '
                },
                {
                    title:'System Notification',
                    date:'Wed 24 Mar, 2021 13:24:28',
                    body:'Amet, ultricies suspendisse ante amet sit pulvinar rhoncus quisque. Orci nulla bibendum risus, egestas nisl turpis. Enim proin aliquet massa dictum ut est. Diam vel id nulla proin. Convallis habitant nibh eu arcu nulla sapien quam risus, '
                }
            ]
        }
    }
}
</script>

<style>
@import url("../../../styles/notification/__index.scss");
</style>